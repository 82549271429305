export class RSAOAEPHelper {
  private readonly dbName: string
  private readonly storeName: string
  private keyPair: CryptoKeyPair | null

  constructor() {
    this.dbName = 'cryptoKeys'
    this.storeName = 'keyStore'
    this.keyPair = null
  }

  // Open or create IndexedDB
  private async _openDB(): Promise<IDBDatabase> {
    return await new Promise((resolve, reject) => {
      const request = indexedDB.open(this.dbName, 1)

      request.onupgradeneeded = event => {
        const req = event.target as IDBRequest
        const db: IDBDatabase = req.result
        db.createObjectStore(this.storeName)
      }

      request.onsuccess = event => {
        const req = event.target as IDBRequest
        resolve(req.result as IDBDatabase)
      }

      request.onerror = event => {
        reject(new Error('Error opening database'))
      }
    })
  }

  // Generate RSA-OAEP keys
  public async generateKey(): Promise<void> {
    this.keyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 4096,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: 'SHA-256'
      },
      false,
      ['encrypt', 'decrypt']
    )
  }

  // Save key to IndexedDB
  public async saveKey(): Promise<void> {
    const db = await this._openDB()
    const transaction = db.transaction(this.storeName, 'readwrite')
    const store = transaction.objectStore(this.storeName)
    store.put(this.keyPair, 'rsaKeyPair')

    await new Promise<void>((resolve, reject) => {
      transaction.oncomplete = () => {
        resolve()
        db.close()
      }

      transaction.onerror = () => {
        reject(new Error('Error saving key to database'))
        db.close()
      }
    })
  }

  // Load key from IndexedDB
  public async loadKey(): Promise<void> {
    const db = await this._openDB()
    const transaction = db.transaction(this.storeName, 'readonly')
    const store = transaction.objectStore(this.storeName)
    const request = store.get('rsaKeyPair')

    await new Promise<void>((resolve, reject) => {
      request.onsuccess = () => {
        this.keyPair = request.result as CryptoKeyPair
        resolve()
        db.close()
      }

      request.onerror = () => {
        reject(new Error('Error loading key from database'))
        db.close()
      }
    })
  }

  // Encrypt using the public key
  public async encrypt(data: string): Promise<string> {
    if (!this.keyPair) throw new Error('No key pair')
    const encrypted = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP'
      },
      this.keyPair.publicKey,
      new TextEncoder().encode(data)
    )
    return this.bufferToBase64(encrypted)
  }

  // Decrypt using the private key
  public async decrypt(data: string): Promise<string> {
    if (!this.keyPair) throw new Error('No key pair')
    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP'
      },
      this.keyPair.privateKey,
      this.base64ToBuffer(data)
    )
    return new TextDecoder().decode(decrypted)
  }

  // Convert a buffer to a Base64 string
  private bufferToBase64(buf: ArrayBuffer): string {
    const byteArr = new Uint8Array(buf)
    const byteString = String.fromCharCode(...byteArr)
    return btoa(byteString)
  }

  // Convert a Base64 string to a buffer
  private base64ToBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64)
    const len = binaryString.length
    const bytes = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
  }
}
