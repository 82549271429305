import { useQuery } from '@tanstack/react-query'
import {
  type Alias,
  type PortfolioCoin,
  type PortfolioNFT
} from '../../types'
import { formatUnits } from 'viem'
import { RecipientAPI } from '../../networking/recipientAPI'

export const usePortfolio = (query: string) => {
  return useQuery<{
    coins: PortfolioCoin[]
    nfts: PortfolioNFT[]
    aliases: Alias[]
  }>(
    ['Portfolio', query],
    async () => {
      const recipientData = await RecipientAPI.resolve([query])

      const response: {
        coins: PortfolioCoin[]
        nfts: PortfolioNFT[]
        aliases: Alias[]
      } = {
        coins: [],
        nfts: [],
        aliases: []
      }

      const portfolioData = await RecipientAPI.getPortfolio(
        recipientData[query].walletAddress
      )

      portfolioData.coins.forEach((coin) => {
        response.coins.push({
          ...coin,
          balance: parseFloat(
            formatUnits(BigInt(coin.balance || ''), coin.decimals || 18)
          ).toFixed(2),
          valueUSD:
            parseFloat(
              formatUnits(BigInt(coin.balance || ''), coin.decimals || 18)
            ) * (coin.exchangeRate || 0)
        })
      })

      portfolioData.nfts.forEach((nft: any) => {
        response.nfts.push(nft)
      })

      response.coins.sort((a, b) => {
        const valueDiff = b.valueUSD - a.valueUSD
        if (valueDiff !== 0) return valueDiff
        return a.name.localeCompare(b.name)
      })

      response.nfts.sort((a, b) => {
        const balanceDiff = parseInt(b.balance) - parseInt(a.balance)
        if (balanceDiff !== 0) return balanceDiff
        return a.name.localeCompare(b.name)
      })

      response.aliases = portfolioData.aliases

      return response
    },
    {
      enabled: !!query
    }
  )
}
