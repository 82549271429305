export class TribesHandler {
  private readonly isReadyPromise: Promise<boolean>
  private resolveReadyPromise?: (x: boolean) => void

  constructor() {
    this.isReadyPromise = new Promise<boolean>(resolve => {
      this.resolveReadyPromise = resolve
    })
  }

  markIsReady() {
    const resolver = this.resolveReadyPromise
    if (resolver) {
      resolver(true)
    }
  }

  async openChat(target: any) {
    await this.isReady()
    this.postToApp({ action: 'open_chat', target })
  }

  async login(target: any) {
    await this.isReady()
    this.postToApp({ action: 'login', target })
  }

  async refresh(target: any) {
    await this.isReady()
    this.postToApp({ action: 'refresh', target })
  }

  async setup(target: any) {
    await this.isReady()
    this.postToApp({ action: 'setup', target })
  }

  // eslint-disable-next-line @typescript-eslint/space-before-function-paren
  async isReady() {
    return await this.isReadyPromise
  }

  // rename to isEthProviderInstalled
  async isMetaMaskInstalled() {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      return true
    }

    const ethereum = (window as any).ethereum
    return (typeof ethereum !== 'undefined')
  }

  // rename to isEthProviderConnected
  async isMetaMaskConnected(): Promise<boolean> {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      return true
    }

    const ethereum = (window as any).ethereum
    if (ethereum === null || ethereum === undefined) {
      return false
    }
    return (await ethereum.request({ method: 'eth_accounts' }) as any[]).length > 0
  }

  async isPhantomConnected(): Promise<boolean> {
    try {
      await (window as any).solana.connect({ onlyIfTrusted: true })
    } catch (e) {
      /* noop */
    }
    return (window as any).solana?.isConnected === true
  }

  async isPhantomInstalled() {
    const solana = (window as any).solana
    return (typeof solana !== 'undefined')
  }

  async requestAccountFromPhantom(): Promise<string | undefined> {
    try {
      const result = await (window as any).solana.connect()
      return result.publicKey?.toBase58()
    } catch (e) {
      console.error('requestAccountFromPhantom', e)
      return undefined
    }
  }

  async dismissUI() {
    (window as any).tribes.close()
  }

  private getTribesClient() {
    const tribesClient: any = ((window as any).tribesClient)
    return tribesClient
  }

  // rename to requestEthAccounts
  async requestAccountsFromMetaMask() {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      const accounts = await tribesClient.requestAddresses()
      return accounts
    }

    const ethereum = (window as any).ethereum
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
    return accounts
  }

  private uint8ArrayToHex(arry: Uint8Array): string {
    return arry.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '')
  }

  async solWeb3SignMessage(message: string, address: string): Promise<string> {
    const encodedMessage = new TextEncoder().encode(message)
    const solana: any = (window as any).solana
    if (solana) {
      const signedMessage: { signature: Uint8Array } = await solana.signMessage(
        encodedMessage,
        'utf8'
      )
      return this.uint8ArrayToHex(signedMessage.signature)
    }
    throw new Error('solana not available')
  }

  async switchEthereumChain(chainId: string) {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      // let achainId = await tribesClient.getChainId()
      // console.log('switchEthereumChain BEFORE chainId', achainId)

      // console.log('switchEthereumChain', chainId)
      return tribesClient.switchChain({ id: chainId })

      // console.log('switchEthereumChain result', cc)
      // achainId = await tribesClient.getChainId()
      // const tribesSwitchNetwork = (window as any).tribesSwitchNetwork
      // const n = await tribesSwitchNetwork(achainId)
      // console.log('switchEthereumChain N', n)
      // console.log('switchEthereumChain AFTER chainId', achainId)
      // return cc
    }
    return await this.makeRequest('wallet_switchEthereumChain', [{ chainId }])
  }

  async ethPersonalSign(message: string, address: string) {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      return tribesClient.signMessage({ message })
    }

    const ethereum = (window as any).ethereum
    if (ethereum === null || ethereum === undefined) {
      throw new Error('ethereum not available')
    }
    await ethereum.request({ method: 'eth_requestAccounts' })
    return await this.makeRequest('personal_sign', [message, address])
  }

  async eth_signTypedData_v4(params: any[]) {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      return tribesClient.signTypedData(params[0])
    }

    return await this.makeRequest('eth_signTypedData_v4', params)
  }

  async eth_sendTransaction(params: any[]) {
    const tribesClient = this.getTribesClient()
    if (tribesClient) {
      return tribesClient.sendTransaction(
        params[0]
      )
    }
    return await this.makeRequest('eth_sendTransaction', params)
  }

  // call javascript method from iframe
  async callMethod(methodName: string, methodArgs: any, requestId: string) {
    try {
      const obj = this as any
      if (typeof obj[methodName] === 'function') {
        // eslint-disable-next-line prefer-spread
        const result = await obj[methodName].apply(obj, methodArgs)

        this.postResult(requestId, result, undefined)
      } else {
        // throw new Error(`TribesHandler method not found: ${methodName}`)
        console.error(`TribesHandler method not found: ${methodName}`)
      }
    } catch (e: any) {
      this.postResult(requestId, undefined, e.message)
    }
  }

  // Send the result back to the iframe
  postResult(requestId: string, result: any, error: any) {
    const message = error
      ? {
        requestId,
        // eslint-disable-next-line @typescript-eslint/indent
        error
      }
      : {
        requestId,
        result
      }

    this.postToApp(message)
  }

  async copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text)
  }

  private async makeRequest(method: string, params: any[]) {
    return (window as any).ethereum.request({ method, params })
  }

  private postToApp(message: any) {
    try {
      // const iframe = document.getElementById(iframeId)
      // if (!iframe) {
      //   throw new Error('Tribes iframe not found')
      // }
      // const contentWindow = (iframe as any).contentWindow
      window.postMessage({ tribes: { ...message, isTribesResponse: true } }, '*')
    } catch (e: any) {
      console.error(`Error posting to iframe ${e?.message}`, e)

      // setTimeout(() => {
      //   location.reload()
      // }, 2000)
    }
  }
}
