import React, { useEffect, useState } from 'react'
import TribesStars from './assets/tribes_stars.png'
import DefaultAvatar from './assets/hexy-default-pfp.png'
import Sticker from './assets/sticker.png'
import ExpandIcon from './assets/expand_more.png'
import CountdownTimer, { KickoffDate } from './components/timer'
import VerifiedBadge from './assets/verified_badge.png'
import VipBadge from './assets/vip_badge.png'
import useFetchLeaderboard from './components/useFetchLeaderboard'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from 'react-datepicker'
import {
  convertToMonday,
  convertToMondayPST,
  convertToNextMonday,
  convertToPST,
  formatDate,
  getNextMonday,
  getStartOfWeek,
  trimToLength
} from './utils'
import { UserTier } from './types'
import { Link } from 'react-router-dom'

interface Props {
  closeTribes: () => void
}

const Leaderboard = ({ closeTribes }: Props) => {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [weekTime, setWeekTime] = useState(0)

  const [dateText, setDateText] = useState('This week')
  const [rewardsEnded, setRewardsEnded] = useState(true)

  const { data, isLoading } = useFetchLeaderboard({
    date: weekTime
  })

  const ref = React.useRef<any>(null)

  useEffect(() => {
    document.documentElement.classList.remove('dark')
    setWeekTime(convertToPST(new Date()).getTime())
    closeTribes()
  }, [])

  useEffect(() => {
    if (selectedDate > new Date(2023, 11, 18)) {
      setRewardsEnded(true)
    } else {
      setRewardsEnded(false)
    }
  }, [selectedDate])

  return (
    <div className="bg-background flex flex-col items-center overflow-auto h-screen">
      <div className={'p-4 w-full sm:w-[29.25rem] lg:p-0'}>
        <div
          className={
            'flex flex-row justify-between h-10 items-center mt-4 w-full'
          }
        >
          <div className="flex flex-row gap-2 items-center">
            <img src={TribesStars} alt="Stars Logo" className="h-10" />
          </div>
          <div className="flex flex-row items-center gap-0">
            {dateText ===
              (Date.now() > KickoffDate.getTime()
                ? 'This week'
                : 'Kickoff') && (
              <div className="font-light text-sm bg-blue text-[#fff] rounded-full px-2 mr-1">
                {!rewardsEnded ? 'Active' : 'Ended'}
              </div>
            )}
            <div
              className="text-sm text-secondary mr-2 cursor-pointer"
              onClick={() => {
                ref.current.setOpen(true)
              }}
            >
              {dateText}
            </div>
            {Date.now() > KickoffDate.getTime() && (
              <DatePicker
                ref={ref}
                showWeekNumbers
                selected={selectedDate}
                onChange={(date: Date) => {
                  const selectedDate = new Date(date)
                  setSelectedDate(selectedDate)

                  const now = new Date()
                  const startOfCurrentWeek = getStartOfWeek(now)

                  const selectedWeekMonday = getStartOfWeek(selectedDate)

                  setWeekTime(
                    convertToMonday(new Date(selectedWeekMonday)).getTime()
                  )

                  if (selectedDate < new Date(2023, 9, 30)) {
                    setDateText('Kickoff')
                    return
                  }

                  if (
                    selectedDate >= startOfCurrentWeek &&
                    selectedDate < getNextMonday(now)
                  ) {
                    setDateText('This week')
                  } else {
                    setDateText(formatDate(selectedWeekMonday))
                  }
                }}
                customInput={
                  <button>
                    <img src={ExpandIcon} alt="expand" className="h-4 mt-2" />
                  </button>
                }
              />
            )}
          </div>
        </div>
        {!rewardsEnded && (
          <div>
            <a href="https://tribes.xyz/stars" target="_blank" rel="noreferrer">
              <div className="flex flex-row items-center gap-4 w-full
             p-6 mt-5 rounded-2xl border border-border">
                <img src={Sticker} alt="sticker" className="h-20 dark:invert" />
                <div className="flex flex-col gap-1">
                  <div className="text-base font-semibold">
                Get ⭐️ by making Tribes better
                  </div>
                  <div className="text-sm text-secondary font-light">
                Get bonus ⭐️ by finishing the week in the top 100
                  </div>
                </div>
              </div>
            </a>
            {isLoading && (
              <div>
                <div className="w-full pb-2 mt-5">
                  <div className="flex flex-row justify-between items-center text-secondary">
                    <div className="tracking-[2px]">PODIUM</div>
                  </div>
                  <div className="hidden min-[400px]:flex flex-row justify-between items-baseline mt-1">
                    <div className="flex flex-col items-center gap-2 p-2 pb-0 w-40">
                      <div className="h-16 w-16 rounded-full bg-border animate-pulse" />
                      <div className="h-4 w-24 bg-border animate-pulse rounded-lg" />
                      <div className="h-2 w-12 bg-border animate-pulse rounded-lg my-0.5" />
                      <div className="h-2 w-16 bg-border animate-pulse rounded-lg my-0.5" />
                    </div>
                    <div className="flex flex-col items-center gap-2 p-2 pb-0 w-40">
                      <div className="h-20 w-20 rounded-full bg-border animate-pulse" />
                      <div className="h-3 w-24 bg-border animate-pulse rounded-lg" />
                      <div className="h-2 w-12 bg-border animate-pulse rounded-lg my-0.5" />
                      <div className="h-2 w-16 bg-border animate-pulse rounded-lg my-0.5" />
                    </div>
                    <div className="flex flex-col items-center gap-2 p-2 pb-0 w-40">
                      <div className="h-16 w-16 rounded-full bg-border animate-pulse" />
                      <div className="h-3 w-24 bg-border animate-pulse rounded-lg" />
                      <div className="h-2 w-12 bg-border animate-pulse rounded-lg my-0.5" />
                      <div className="h-2 w-16 bg-border animate-pulse rounded-lg my-0.5" />
                    </div>
                  </div>
                  <div className="flex min-[400px]:hidden flex-col">
                    {Array.from(Array(3).keys()).map((i) => (
                      <div
                        className="flex flex-row justify-between py-3.5 items-center"
                        key={i}
                      >
                        <div className="flex flex-row items-center gap-4">
                          <div className="h-2 w-2 bg-border animate-pulse rounded-lg" />
                          <div className="h-10 w-10 rounded-full bg-border animate-pulse" />
                          <div className="flex flex-col gap-1">
                            <div className="h-3 w-20 bg-border animate-pulse rounded-lg" />
                            <div className="h-2 w-14 bg-border animate-pulse rounded-lg" />
                          </div>
                        </div>
                        <div className="flex flex-col gap-1 items-end">
                          <div className="h-3 w-10 bg-border animate-pulse rounded-lg" />
                          <div className="h-2 w-4 bg-border animate-pulse rounded-lg" />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="border border-border w-full mt-4 rounded-md" />
                <div className="w-full mt-6 text-secondary">
                  <div className="tracking-[2px] mb-2">THE WINNERS</div>
                  {Array.from(Array(14).keys()).map((i) => (
                    <div
                      className="flex flex-row justify-between py-3.5 items-center"
                      key={i}
                    >
                      <div className="flex flex-row items-center gap-4">
                        <div className="h-2 w-2 bg-border animate-pulse rounded-lg" />
                        <div className="h-10 w-10 rounded-full bg-border animate-pulse" />
                        <div className="flex flex-col gap-1">
                          <div className="h-3 w-20 bg-border animate-pulse rounded-lg" />
                          <div className="h-2 w-14 bg-border animate-pulse rounded-lg" />
                        </div>
                      </div>
                      <div className="flex flex-col gap-1 items-end">
                        <div className="h-3 w-10 bg-border animate-pulse rounded-lg" />
                        <div className="h-2 w-4 bg-border animate-pulse rounded-lg" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {!isLoading && (data?.length ?? 0) === 0 && (
              <div className="mt-4 flex flex-col items-center text-secondary">
            No data available for this week
              </div>
            )}
            {!isLoading && (data?.length ?? 0) > 0 && (
              <div>
                <div className="w-full pb-2 mt-5 ">
                  <div className="flex flex-row justify-between items-center text-secondary">
                    <div className="tracking-[2px]">PODIUM</div>
                    {dateText === 'This week' && <CountdownTimer />}
                  </div>
                  <div className="hidden min-[400px]:flex flex-row justify-between items-baseline mt-1">
                    <Link
                      className="flex flex-col items-center gap-2 p-2 pb-0 w-40"
                      to={`/${
                    data?.[1].displayName === data?.[1].walletAddress.prefix
                      ? data?.[1].walletAddress.value
                      : data?.[1].displayName
                      }`}
                    >
                      <img
                        src={data?.[1].image?.toString() ?? DefaultAvatar}
                        alt="user"
                        className="h-16 w-16 rounded-full"
                      />

                      <div className="flex flex-row items-center gap-1">
                        <div className="text-base font-semibold">
                          {trimToLength(data?.[1].displayName ?? '', 16)}
                        </div>
                        {data?.[1].tier === UserTier.vip && (
                          <img src={VipBadge} alt="logo" className="h-4" />
                        )}
                        {data?.[1].tier === UserTier.verified && (
                          <img src={VerifiedBadge} alt="logo" className="h-4" />
                        )}
                      </div>
                      <div className="text-secondary">{data?.[1].stars} ⭐️</div>
                      <div
                        className={
                          'px-2 bg-green font-light text-[#fff] rounded-full text-xs sm:text-sm'
                        }
                      >
                    +$10 bonus
                      </div>
                    </Link>
                    <Link
                      className="flex flex-col items-center gap-2 p-2 pb-0 w-40"
                      to={`/${
                    data?.[0].displayName === data?.[0].walletAddress.prefix
                      ? data?.[0].walletAddress.value
                      : data?.[0].displayName
                      }`}
                    >
                      <img
                        src={data?.[0].image?.toString() ?? DefaultAvatar}
                        alt="user"
                        className="h-20 w-20 rounded-full"
                      />
                      <div className="flex flex-row items-center gap-1">
                        <div className="text-base font-semibold">
                          {trimToLength(data?.[0].displayName ?? '', 16)}
                        </div>
                        {data?.[0].tier === UserTier.vip && (
                          <img src={VipBadge} alt="logo" className="h-4" />
                        )}
                        {data?.[0].tier === UserTier.verified && (
                          <img src={VerifiedBadge} alt="logo" className="h-4" />
                        )}
                      </div>
                      <div className="text-secondary">{data?.[0].stars} ⭐️</div>
                      <div
                        className={
                          'px-2 bg-green font-light text-[#fff] rounded-full text-xs sm:text-sm'
                        }
                      >
                    +$20 bonus
                      </div>
                    </Link>
                    <Link
                      className="flex flex-col items-center gap-2 p-2 pb-0 w-40"
                      to={`/${
                    data?.[2].displayName === data?.[2].walletAddress.prefix
                      ? data?.[2].walletAddress.value
                      : data?.[2].displayName
                      }`}
                    >
                      <img
                        src={data?.[2].image?.toString() ?? DefaultAvatar}
                        alt="user"
                        className="h-16 w-16 rounded-full"
                      />

                      <div className="flex flex-row items-center gap-1">
                        <div className="text-base font-semibold">
                          {trimToLength(data?.[2].displayName ?? '', 16)}
                        </div>
                        {data?.[2].tier === UserTier.vip && (
                          <img src={VipBadge} alt="logo" className="h-4" />
                        )}
                        {data?.[2].tier === UserTier.verified && (
                          <img src={VerifiedBadge} alt="logo" className="h-4" />
                        )}
                      </div>
                      <div className="text-secondary">{data?.[2].stars} ⭐️</div>
                      <div
                        className={
                          'px-2 bg-green font-light text-[#fff] rounded-full text-xs sm:text-sm'
                        }
                      >
                    +$5 bonus
                      </div>
                    </Link>
                  </div>
                  <div className="flex min-[400px]:hidden flex-col">
                    {data?.slice(0, 3).map((item, index) => (
                      <Link
                        className="flex flex-row justify-between py-3.5 w-full"
                        key={item.walletAddress.value}
                        to={`/${
                      item.displayName === item.walletAddress.prefix
                        ? item.walletAddress.value
                        : item.displayName
                        }`}
                      >
                        <div className="flex flex-row items-center gap-4">
                          <div className="text-sm w-4">{index + 1}</div>
                          <img
                            src={item.image?.toString() ?? DefaultAvatar}
                            alt="user"
                            className="h-10 w-10 rounded-full"
                          />
                          <div className="flex flex-col items-start">
                            <div className="flex flex-row items-center gap-1">
                              <div className="text-base font-semibold text-primary">
                                {trimToLength(item.displayName, 16)}
                              </div>
                              {item.tier === UserTier.vip && (
                                <img src={VipBadge} alt="logo" className="h-4" />
                              )}
                              {item.tier === UserTier.verified && (
                                <img
                                  src={VerifiedBadge}
                                  alt="logo"
                                  className="h-4"
                                />
                              )}
                            </div>
                            <div className="text-sm">
                              {item.walletAddress.prefix}
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col text-right">
                          <div>
                            {item.stars} {index === 0 ? '⭐️' : ''}
                          </div>
                          <div className="text-sm text-green">
                            {index === 0
                          ? '+$20 bonus'
                          : index === 1
                          ? '+$10'
                          : '+$5'}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className="border border-border full mt-4 rounded-md" />
                <div className="w-full mt-6 text-secondary">
                  <div className="tracking-[2px] mb-2">THE WINNERS</div>
                  {data?.slice(3, 100).map((item, index) => (
                    <Link
                      className="flex flex-row justify-between py-3.5 w-full"
                      key={item.walletAddress.value}
                      to={`/${
                    item.displayName === item.walletAddress.prefix
                      ? item.walletAddress.value
                      : item.displayName
                      }`}
                    >
                      <div className="flex flex-row items-center gap-4">
                        <div className="text-sm w-4">{index + 4}</div>
                        <img
                          src={item.image?.toString() ?? DefaultAvatar}
                          alt="user"
                          className="h-10 w-10 rounded-full"
                        />

                        <div className="flex flex-col items-start">
                          <div className="flex flex-row items-center gap-1">
                            <div className="text-base font-semibold text-primary">
                              {item.displayName}
                            </div>
                            {item.tier === UserTier.vip && (
                              <img src={VipBadge} alt="logo" className="h-4" />
                            )}
                            {item.tier === UserTier.verified && (
                              <img src={VerifiedBadge} alt="logo" className="h-4" />
                            )}
                          </div>
                          <div className="text-sm">{item.walletAddress.prefix}</div>
                        </div>
                      </div>
                      <div className="flex flex-col text-right">
                        <div>
                          {item.stars} {index === 0 ? '⭐️' : ''}
                        </div>
                        <div className="text-sm text-green">
                      +$0.69 {index === 0 ? 'Bonus' : ''}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}
          </div>)}
        {rewardsEnded && (
          <div className="mt-20 flex flex-col items-center text-secondary">
            Thank you for your valuable participation in the Tribes Rewards Program.
            We wanted to inform you that this program has now concluded 😅.
            We truly appreciate your involvement and look forward to bringing
            you more exciting opportunities in the future.

            <p className='mt-6 text-primary'>Follow us on X.com <a className="underline"
              href="https://twitter.com/tribes_xyz">
              https://twitter.com/tribes_xyz</a>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Leaderboard
