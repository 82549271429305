import React, { useEffect } from 'react'
import { tribesHandler } from './shared/constants'
import { useAccount } from 'wagmi'
import { useParams } from 'react-router-dom'
import { useAtom } from 'jotai'
import { store } from './store'

interface Props {
  openTribes: () => void
}

const ChatPage = ({ openTribes }: Props) => {
  const { isConnected: isWagmiConnected } = useAccount()
  const { user } = useParams()

  const [isConnectedCache] = useAtom(store.isConnectedCacheAtom)
  const isConnected = isWagmiConnected || isConnectedCache

  useEffect(() => {
    const open = async () => {
      openTribes()
      await tribesHandler.openChat({
        type: 'profile',
        query: user
      })
      await tribesHandler.refresh({})
    }
    void open()
  }, [isConnected])

  return (
    <div/>
  )
}

export default ChatPage
