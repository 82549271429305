import { useEffect } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { RewardsAPI } from '../networking/rewardsAPI'
import { RecipientAPI } from '../networking/recipientAPI'
import { type ReputableRecipientWithStars } from '../types'

const getLeaderboard = async (date: number): Promise<ReputableRecipientWithStars[]> => {
  const leaderboardData = await RewardsAPI.getLeaderboard(date)

  const resolved = await RecipientAPI.resolve(
    leaderboardData.map((x) => x.address)
  )

  const recipients = leaderboardData.map(
    (x) => ({
      ...resolved[x.address.value],
      stars: x.stars
    })
  )

  return recipients
}

interface Props {
  date: number
}

const useFetchLeaderboard = ({ date }: Props) => {
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery(
    ['leaderboard', date],
    async () => {
      const recipients = await getLeaderboard(date)
      return recipients
    },
    {
      enabled: !!date
    }
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    const intervalId = setInterval(async () => {
      const recipients = await getLeaderboard(date)
      queryClient.setQueryData(['leaderboard', date], recipients)
    }, 60 * 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [date])

  return { isLoading, data }
}

export default useFetchLeaderboard
