import React from 'react'
import { AssetTier, EthChain, type PortfolioCoin, type PortfolioNFT } from '../../types'
import AssetContextMenu from '../contextMenu'
import Ethereum from '../../assets/ethereum.png'
import Polygon from '../../assets/polygon.png'
import VerifiedBadge from '../../assets/verified_badge.png'
import DefaultAvatar from '../../assets/hexy-default-pfp.png'
import { trimToLength } from '../../utils'

interface AssetItemProps {
    item: PortfolioCoin | PortfolioNFT
    refetch: () => Promise<any>
  }

export const AssetItem = ({ item, refetch }: AssetItemProps) => (
  <AssetContextMenu
    name={item.name}
    id={item.id}
    address={item.address.value}
    tier={item.tier}
    refetch={refetch}
  >
    <div
      className="flex flex-row items-center cursor-pointer py-4 h-[4.5rem] justify-between"
      key={item.address.value}
      onClick={() => {
        switch (item.chainId) {
          case EthChain.ethereum:
            window.open(
              `https://etherscan.io/address/${item.address.value}`,
              '_blank'
            )
            break
          case EthChain.polygon:
            window.open(
              `https://polygonscan.com/address/${item.address.value}`,
              '_blank'
            )
            break
          default:
            break
        }
      }}
    >
      <div className="flex flex-row items-center gap-2">
        <div>
          <img
            src={item.image ?? DefaultAvatar}
            alt="avatar"
            className={`h-10 w-10 ${
                  item.type === 'COIN' ? 'rounded-full' : 'rounded-md bg-border'
            }`}
          />
        </div>
        <div className="flex flex-col">
          <div className="flex flex-row items-center gap-1">
            <div className="text-md font-semibold">
              {trimToLength(item.name, 32)}
            </div>
            {item.tier === AssetTier.verified && (
              <img src={VerifiedBadge} alt="logo" className="h-4" />
            )}
            {item.tier === AssetTier.spam && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="#FF9900"
                className="w-4 h-4"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385
                  2.25 12 2.25zm-1.72
                  6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12
                  13.06l1.72 1.72a.75.75 0
                  101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
          <div className="text-sm flex flex-row gap-2 text-secondary">
            <img
              src={item.chainId === EthChain.ethereum ? Ethereum : Polygon}
              alt="ethereum"
              className={'h-4 w-4 rounded-full'}
            />
            <div>{item.address.prefix}</div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div className="font-semibold">
          {item.type === 'COIN'
              ? `$${item.valueUSD.toFixed(2)}`
              : `${item.balance} NFTs`}
        </div>
        <div className="text-secondary text-sm">
          {item.type === 'COIN' ? `${item.balance} ${item.symbol}` : ''}
        </div>
      </div>
    </div>
  </AssetContextMenu>
)
