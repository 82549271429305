import React from 'react'
import { useAccount, useWalletClient } from 'wagmi'
import { type WalletClient } from '@wagmi/core'
import { Outlet, useMatch } from 'react-router-dom'
import Welcome from '../Welcome'

interface Props {
  isOpen: boolean
}

function setup(client: WalletClient): void {
  try {
    if (!(window as any).tribesClient) {
      console.log('=> no ethereum. setting up tribesClient');
      (window as any).tribesClient = client
    }
  } catch (e) {
    console.log('=> cb error', e)
  }
}

const Tribes = ({ isOpen }: Props) => {
  const { isConnected } = useAccount()
  const { data: walletClient } = useWalletClient()

  if (isConnected && walletClient !== undefined && walletClient !== null) {
    setup(walletClient)
  }

  const userMatch = useMatch('/:user')
  const inviteMatch = useMatch('/invite/:id')
  const leaderboardMatch = useMatch('/leaderboard')

  const isChatPage = (userMatch ?? inviteMatch) && !leaderboardMatch

  return (
    <div>
      <Outlet />
      <div
        id="frameContainer"
        className={isOpen ? 'frameContainer-open' : 'frameContainer-closed'}
      >
        <div
          id="flutter_target"
          className="mainstage"
          style={{
            display: isChatPage ? isConnected ? 'block' : 'none' : 'block'
          }}
        ></div>

        <div
          id="welcome"
          className="mainstage"
          style={{ display: isChatPage ? isConnected ? 'none' : 'block' : 'none' }}
        >
          <Welcome />
        </div>
      </div>
    </div>
  )
}

export default Tribes
