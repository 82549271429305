import { asEthWalletAddress, asNumber, type GetLeaderboardResponse } from '../types'

async function getLeaderboard(date: number): Promise<GetLeaderboardResponse[]> {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/get_leaderboard`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      date
    })
  })
  const data = await res.json()
  return data.map((x: any) => ({
    stars: asNumber(x.stars),
    address: asEthWalletAddress(x.address)
  }))
}

export const RewardsAPI = {
  getLeaderboard
}
