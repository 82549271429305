import { isAddress } from 'viem'
import { PublicKey as SolPublicKey } from '@solana/web3.js'

const ipfsGatewayPrefixes = [
  'ipfs://',
  'ifps://', // yep, some genius decided to use this instead of ipfs
  'https://cloudflare-ipfs.com/ipfs/',
  'https://gateway.pinata.cloud/ipfs/',
  'https://ipfs.madewithmason.com/ipfs/',
  'https://gateway.moralisipfs.com/ipfs/',
  'https://ipfs.io/ipfs/',
  'https://ipfs.moralis.io:2053/ipfs/',
  'https://nftpuzzlething.mypinata.cloud/ipfs/'
]

const ipfsGateway = 'https://tribes.mypinata.cloud/ipfs/'

export function isNull(obj: any): obj is null | undefined {
  return obj === null || obj === undefined
}

export function isRequiredString(arg: any): arg is string {
  return typeof arg === 'string'
}

export function asString(arg: any): string {
  if (!isRequiredString(arg)) throw new Error(`invalid string ${arg}`)
  return arg
}

export function asStringOrNull(arg: any): string | undefined {
  if (isNull(arg)) return undefined
  return asString(arg)
}

export function applyOptional<T, R>(
  obj: T | null | undefined,
  fn: (arg: T) => R
): R | undefined {
  return isNull(obj) ? undefined : fn(obj)
}

export function isRequiredNumber(arg: any): arg is number {
  return typeof arg === 'number'
}

export function asNumber(arg: any): number {
  if (!isRequiredNumber(arg)) throw new Error(`Invalid number ${arg}`)
  return arg
}

export function asOptionalNumber(arg: any): number | undefined {
  return applyOptional(arg, asNumber)
}

export function prepend0x(hex: string) {
  return hex.replace(/^(0x)?/i, '0x')
}
export class EthWalletAddress {
  private readonly value_: string

  constructor(value: string) {
    if (!isAddress(value)) {
      throw new Error(`Invalid ETH wallet address: ${value}`)
    }

    this.value_ = prepend0x(value.toLowerCase())
  }

  get prefix(): string {
    return this.value.slice(0, 9)
  }

  get value(): string {
    return this.value_
  }

  toJSON() {
    return this.value
  }

  toString() {
    return this.value
  }
}

export function asEthWalletAddress(arg: any): EthWalletAddress {
  return new EthWalletAddress(arg)
}

export enum UserTier {
  fresh = 'fresh',
  verified = 'verified',
  vip = 'vip',
}

export function isUserTier(arg: any): arg is UserTier {
  return arg in UserTier
}

export function asUserTier(arg: any): UserTier {
  if (isUserTier(arg)) {
    return arg
  }
  throw new Error(`invalid UserTier ${arg}`)
}

export class SolWalletAddress {
  private readonly value_: string

  constructor(value: string) {
    const pubkey = new SolPublicKey(value)
    if (!SolPublicKey.isOnCurve(pubkey.toBytes())) {
      throw new Error(`invalid Sol public key / wallet address ${value}`)
    }

    this.value_ = value
  }

  get prefix(): string {
    return this.value.slice(0, 9)
  }

  get value(): string {
    return this.value_
  }

  toJSON() {
    return this.value
  }

  toString() {
    return this.value
  }

  toWalletAddress(): SolWalletAddress {
    return this
  }

  toPublicKey(): SolPublicKey {
    return new SolPublicKey(this.value)
  }
}

export type WalletAddress = EthWalletAddress | SolWalletAddress

export function asWalletAddress(arg: any): WalletAddress {
  if (!isRequiredString(arg)) {
    throw new Error(`missing or invalid wallet address, not a string (${arg})`)
  }

  try {
    return new EthWalletAddress(arg)
  } catch {}

  try {
    return new SolWalletAddress(arg)
  } catch {}

  throw new Error(`invalid wallet address ${arg}`)
}

export function asHTTPURL(arg: string | URL): URL {
  if (isNull(arg)) {
    throw new Error('URL is null')
  }

  let urlString = arg.toString()
  // rewrite ipfs gateways & ipfs: protocol
  for (const prefix of ipfsGatewayPrefixes) {
    urlString = urlString.replace(prefix, ipfsGateway)
  }

  return new URL(urlString)
}

export function asHTTPURLOrNull(
  arg: string | URL | undefined
): URL | undefined {
  try {
    if (isNull(arg)) {
      return undefined
    }
    return asHTTPURL(arg)
  } catch (e) {
    return undefined
  }
}

export interface Recipient {
  displayName: string
  walletAddress: WalletAddress
  image?: URL
  user?: any
}

export function asRecipient(arg: any): Recipient {
  const displayName = asString(arg.displayName)
  const walletAddress = asWalletAddress(arg.walletAddress)
  const image = asHTTPURLOrNull(arg.image)
  // const user = arg.user ? asUser(arg.user) : undefined

  return {
    displayName,
    walletAddress,
    image,
    user: arg.user
  }
}

export enum NameResolutionSource {
  unknown = 'unknown',
  tribes = 'tribes',
  ens = 'ens',
  lens = 'lensProtocol',
  unstoppable = 'unstoppable',
}

export type ReputableRecipient = Recipient & {
  tier: UserTier
  walletAge?: number // epoch milliseconds
  reputation?: number // star score
  source?: NameResolutionSource
}

export type ReputableRecipientWithStars = ReputableRecipient & {
  stars: number
}

export function isNameResolutionSource(arg: any): arg is NameResolutionSource {
  return arg in NameResolutionSource
}

export function asNameResolutionSourceOrNull(
  arg: any
): NameResolutionSource | undefined {
  if (isNameResolutionSource(arg)) {
    return arg
  }
  return undefined
}

export function asReputableRecipient(arg: any): ReputableRecipient {
  const recipient = asRecipient(arg)
  const tier = asUserTier(arg.tier)
  const walletAge = asOptionalNumber(arg.walletAge)
  const reputation = asOptionalNumber(arg.reputation)
  const source = asNameResolutionSourceOrNull(arg.source)
  return { ...recipient, tier, walletAge, reputation, source }
}

export type RecipientSearchResponse = ReputableRecipient & {
  secondaryName: string
}

export function asRecipientSearchResponse(arg: any): RecipientSearchResponse {
  const recipient = asReputableRecipient(arg)
  const secondaryName = asString(arg.secondaryName)
  return { ...recipient, secondaryName }
}

export enum EthChain {
  ethereum = 1,
  polygon = 137,
  base = 8453,
  optimism = 10,
}

export function asEthChain(item: any): EthChain {
  if (isRequiredNumber(item) && item in EthChain) {
    return item
  }
  throw new Error(`invalid EthChain [${item}]`)
}

export enum SearchType {
  user = 'user',
}

export enum AssetSearchType {
  coin = 'coin',
  nft = 'nft',
}

export type CombinedSearchType = SearchType | AssetSearchType

export function isAssetSearchType(arg: any): arg is AssetSearchType {
  return arg in AssetSearchType
}

export function asAssetSearchType(arg: any): AssetSearchType {
  if (isAssetSearchType(arg)) {
    return arg
  }
  throw new Error(`invalid AssetSearchType ${arg}`)
}

export enum AssetTier {
  verified = 'verified',
  standard = 'standard',
  spam = 'spam',
}

export interface Asset {
  id: string
  tier: AssetTier
  chainId: EthChain
  name: string
  address: EthWalletAddress
  image?: string
}

export function asAsset(arg: any): Asset {
  const id = asString(arg.id)
  const tier = asAssetTier(arg.tier)
  const chainId = asEthChain(arg.chainId)
  const image = asStringOrNull(arg.image)
  const name = asString(arg.name)
  const address = asEthWalletAddress(arg.address)
  return { id, tier, chainId, image, name, address }
}

export function isAssetTier(arg: any): arg is AssetTier {
  return arg in AssetTier
}

export function asAssetTier(arg: any): AssetTier {
  if (isAssetTier(arg)) {
    return arg
  }
  throw new Error(`invalid AssetTier ${arg}`)
}

interface PortfolioAsset {
  id: string
  chainId: EthChain
  address: EthWalletAddress
  name: string
  symbol: string
  balance: string
  tier: AssetTier
  image?: string
}

export function asPortfolioAsset(arg: any): PortfolioAsset {
  const id = asString(arg.id)
  const chainId = asEthChain(arg.chainId)
  const address = asEthWalletAddress(arg.address)
  const name = asString(arg.name)
  const symbol = asStringOrNull(arg.symbol) ?? ''
  const balance = asStringOrNull(arg.balance) ?? '0'
  const tier = asAssetTier(arg.tier)
  const image = asStringOrNull(arg.image)
  return { id, chainId, address, name, symbol, balance, tier, image }
}

export interface PortfolioCoin extends PortfolioAsset {
  type: 'COIN'
  decimals: number
  valueUSD: number
  exchangeRate: number
}

export function asPortfolioCoin(arg: any): PortfolioCoin {
  const asset = asPortfolioAsset(arg)
  const decimals = asNumber(arg.decimals)
  const valueUSD = asOptionalNumber(arg.valueUSD) ?? 0
  const exchangeRate = asOptionalNumber(arg.exchangeRate) ?? 0
  return { ...asset, decimals, valueUSD, type: 'COIN', exchangeRate }
}

export interface PortfolioNFT extends PortfolioAsset {
  type: 'NFT'
}

export function asPortfolioNFT(arg: any): PortfolioNFT {
  const asset = asPortfolioAsset(arg)
  return { ...asset, type: 'NFT' }
}

export interface GetLeaderboardResponse {
  address: EthWalletAddress
  stars: number
}

export interface Alias {
  name: string
  source: NameResolutionSource
}
