import {
  asReputableRecipient,
  type WalletAddress,
  type EthWalletAddress,
  type ReputableRecipient,
  type PortfolioCoin,
  type PortfolioNFT,
  asPortfolioCoin,
  asPortfolioNFT,
  type Alias
} from '../types'

async function resolve(
  addresses: Array<EthWalletAddress | string>
): Promise<Record<string, ReputableRecipient>> {
  const res = await fetch(
    `${process.env.REACT_APP_API_ROUTE}/resolve_recipients`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        queries: addresses
      })
    }
  )
  const data = await res.json()
  if (data.recipients) {
    Object.entries(data.recipients).forEach(([key, value]) => {
      data.recipients[key] = asReputableRecipient(value)
    })
    return data.recipients
  }
  return {}
}

async function getPortfolio(walletAddress: WalletAddress): Promise<{
  coins: PortfolioCoin[]
  nfts: PortfolioNFT[]
  aliases: Alias[]
}> {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/get_portfolio`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      walletAddress: walletAddress.value,
      includeErc1155: true,
      isHydrated: true,
      includeBalances: true
    })
  })
  const data = await res.json()
  data.coins = data.coins.map((coin: any) => asPortfolioCoin(coin))
  data.nfts = data.nfts.map((nft: any) =>
    asPortfolioNFT({
      ...nft.erc721,
      ...nft.erc1155,
      balance: nft.items?.length.toString()
    })
  )
  return data
}

export const RecipientAPI = {
  resolve,
  getPortfolio
}
