import { ec as EC } from 'elliptic'
import { type EthWalletAddress } from '../types'
import { Secp256k1, Secp256k1PublicKey } from '../utils/secp256k1'
import { RSAOAEPHelper } from '../utils/webCrypto'
import { AdminService } from '../utils/adminService'

async function getMessageToSign(walletAddress: EthWalletAddress) {
  let publicKey = localStorage.getItem('publicKey')
  let proxy: EC.KeyPair

  if (!publicKey) {
    proxy = Secp256k1.generateKeyPair()
    const pubKey = Secp256k1PublicKey.fromEcKeyPair(proxy)

    const publicKeyString = proxy.getPublic(true, 'hex')
    const privateKeyString = proxy.getPrivate('hex')

    const cryptoHelper = new RSAOAEPHelper()
    await cryptoHelper.generateKey()
    await cryptoHelper.saveKey()
    await cryptoHelper.loadKey()

    const encryptedPrivateKey = await cryptoHelper.encrypt(privateKeyString)

    localStorage.setItem('publicKey', publicKeyString)
    localStorage.setItem('privateKey', encryptedPrivateKey)

    publicKey = pubKey.value
  } else {
    const ec = new EC('secp256k1')
    const cryptoHelper = new RSAOAEPHelper()
    await cryptoHelper.loadKey()
    const privateKeyString = await cryptoHelper.decrypt(
      localStorage.getItem('privateKey') as string
    )
    proxy = ec.keyFromPrivate(privateKeyString, 'hex')
  }

  const res = await fetch(
    `${process.env.REACT_APP_API_ROUTE}/get_message_to_sign`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        walletAddress: walletAddress.value,
        proxy: publicKey,
        type: 'eoa'
      })
    }
  )

  const data = await res.json()
  return {
    message: data.message,
    proxy
  }
}

async function loginWithSignature(
  message: string,
  signature: string,
  deviceSignature: string
) {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      message,
      signature,
      deviceSignature
    })
  })

  const token = await res.text()
  await AdminService.setToken(token)
}

async function isAdminLoggedIn() {
  try {
    const token = await AdminService.getToken()
    const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/is_admin_logged_in`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
    if (!res.ok) {
      return false
    }
    return true
  } catch (e) {
    return false
  }
}

export const LoginAPI = {
  getMessageToSign,
  loginWithSignature,
  isAdminLoggedIn
}
