import React, { useEffect, useRef, useState } from 'react'
import TribesAlpha from '../../assets/tribes_alpha.png'
import { useQuery } from '@tanstack/react-query'
import { useDebounce } from '@uidotdev/usehooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
  type Asset,
  type AssetSearchType,
  asAssetSearchType
} from '../../types'
import { AssetItem } from './item'
import { SearchAPI } from '../../networking/searchAPI'
import { LoginAPI } from '../../networking/loginAPI'

const useSearch = (type: AssetSearchType, query: string) => {
  return useQuery<Asset[]>(
    [`${type}Search`, query],
    async () => {
      return await SearchAPI.search({
        type,
        query: encodeURIComponent(query.toLowerCase())
      })
    },
    {
      enabled: !!query
    }
  )
}

const Assets = () => {
  const [query, setQuery] = useState('')
  const debouncedSearchTerm = useDebounce(query, 300)

  const { type } = useParams()

  const {
    data: searchRes,
    isFetched,
    refetch,
    isLoading
  } = useSearch(asAssetSearchType(type), debouncedSearchTerm)
  const navigate = useNavigate()

  useEffect(() => {
    document.documentElement.classList.remove('dark')

    void LoginAPI.isAdminLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate('/admin/login')
      }
    })
  }, [])

  const isError = !searchRes?.map && isFetched && query.length > 0
  const noResults =
    searchRes?.map && searchRes.length === 0 && query.length > 0

  return (
    <div
      className="bg-background text-primary flex flex-col overflow-auto"
      style={{
        height: '100vh'
      }}
    >
      <div className="flex flex-row justify-between w-full p-4 h-[4.5rem] items-center">
        <div className="flex flex-row gap-2 items-center ml-2">
          <img
            src={TribesAlpha}
            alt="Tribes Logo"
            className="h-10 dark:invert hidden lg:flex"
          />
        </div>
      </div>
      <div className={'flex flex-col items-center flex-grow mb-40'}>
        <div
          className={
            'w-full transition-all duration-300 ease-in-out flex flex-col items-center'
          }
        >
          <div className={'p-4 pt-0 w-[30rem]'}>
            <div
              className="flex flex-row items-center border border-border h-10 rounded-full
            text-md px-4 py-2 mt-4"
            >
              <div className="text-secondary mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </div>
              <input
                value={query}
                className="w-full focus:outline-none placeholder-placeholder bg-transparent"
                type="search"
                name="search"
                placeholder={
                  type === 'coin' ? 'Search for coins' : 'Search for nfts'
                }
                onChange={(e) => {
                  setQuery(e.target.value)
                }}
                onFocus={() => {
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                  }, 200)
                }}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="w-[30rem] flex flex-col">
            {isError && (
              <div className="flex flex-col items-center justify-center p-4">
                <div className="text-sm text-secondary">
                  Something went wrong 😢
                </div>
              </div>
            )}
            {noResults && (
              <div className="flex flex-col items-center justify-center p-4">
                <div className="text-sm text-secondary">
                  No {type === 'coin' ? 'Coins' : 'NFTs'} match your search 😢
                </div>
                <div className="text-sm text-secondary">
                  Try a different search term
                </div>
              </div>
            )}
            {query.length > 0 && isLoading && (
              <div>
                {Array.from(Array(14).keys()).map((i) => (
                  <div role="status" className="max-w-sm animate-pulse" key={i}>
                    <div className="flex flex-row items-center gap-2 p-4 h-[4.5rem]">
                      <div className="h-10 w-10 rounded-full bg-border" />
                      <div className="flex flex-col gap-2">
                        <div className="h-4 w-32 bg-border rounded-lg" />
                        <div className="text-sm flex flex-row gap-2">
                          <div className="h-2 w-16 bg-border rounded-lg" />
                          <div className="h-2 w-12 bg-border rounded-lg" />
                          <div className="h-2 w-14 bg-border rounded-lg" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {query.length > 0 &&
              !isLoading &&
              !isError &&
              searchRes?.map((item) => (
                <AssetItem
                  item={item}
                  key={item.address.value}
                  refetch={refetch}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Assets
