import React, { useEffect, useState } from 'react'
import { convertToMonday, convertToNextMonday, convertToPST } from '../utils'

export const KickoffDate = new Date()
KickoffDate.setMonth(9)
KickoffDate.setDate(30)

convertToMonday(KickoffDate)

const CountdownTimer: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<string>('')

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date()

      const nextMonday = new Date()
      convertToNextMonday(convertToPST(nextMonday))

      const diff = nextMonday.getTime() - now.getTime()
      if (diff < 0) return '0d 00h 00m 00s left'
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
      const minutes = Math.floor((diff / (1000 * 60)) % 60)
      const seconds = Math.floor((diff / 1000) % 60)

      // Pad single-digit values with a leading zero
      const pad = (num: number) => (num < 10 ? '0' : '') + num

      return `${days}d ${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s left`
    }

    const updateTimer = () => {
      setTimeLeft(calculateTimeLeft())
    }

    updateTimer()
    const intervalId = setInterval(updateTimer, 1000)

    return () => { clearInterval(intervalId) }
  }, [])

  return (
    <div className="text-sm">{timeLeft}</div>
  )
}

export default CountdownTimer
