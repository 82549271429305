import { RSAOAEPHelper } from './webCrypto'

async function getToken() {
  const encryptedToken = localStorage.getItem('tribes_admin_token') as string
  const cryptoHelper = new RSAOAEPHelper()
  await cryptoHelper.loadKey()
  const token = await cryptoHelper.decrypt(encryptedToken)
  return token
}

async function setToken(token: string) {
  const cryptoHelper = new RSAOAEPHelper()
  await cryptoHelper.loadKey()
  const encryptedToken = await cryptoHelper.encrypt(token.replace(/"/g, ''))
  localStorage.setItem('tribes_admin_token', encryptedToken)
}

export const AdminService = {
  getToken,
  setToken
}
