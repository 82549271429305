import React, { useEffect, useState } from 'react'
import TribesLogo from './assets/tribes_logo_light.webp'
import TribesAlpha from './assets/tribes_alpha.png'
import InfoIcon from './assets/infoIcon.png'
import VerifiedBadge from './assets/verified_badge.png'
import VipBadge from './assets/vip_badge.png'
import DefaultAvatar from './assets/hexy-default-pfp.png'
import { timeSince, trimToLength } from './utils'
import { useDebounce } from '@uidotdev/usehooks'
import { useAccount } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { UserTier, asEthWalletAddress, SearchType } from './types'
import { useNavigate } from 'react-router-dom'
import { ConnectKitButton, useModal } from 'connectkit'
import { RecipientAPI } from './networking/recipientAPI'
import { SearchAPI } from './networking/searchAPI'
import { useAtom } from 'jotai'
import { store } from './store'

interface Props {
  openTribes: () => void
  closeTribes: () => void
}

const LandingPage = ({ closeTribes, openTribes }: Props) => {
  const [query, setQuery] = useAtom(store.queryAtom)
  const [deepSearch, setDeepSearch] = useAtom(store.deepSearchAtom)
  const [isFocused, setIsFocused] = useAtom(store.isFocusedAtom)
  const { address, isConnected: isWagmiConnected } = useAccount()
  const debouncedSearchTerm = useDebounce(query, 300)

  const { setOpen } = useModal()
  const [clickedProfile, setClickedProfile] = useState('')
  const navigate = useNavigate()

  const [isConnectedCache] = useAtom(store.isConnectedCacheAtom)
  const isConnected = isWagmiConnected || isConnectedCache

  useEffect(() => {
    if (isConnected) {
      if (clickedProfile) {
        navigate(`/${clickedProfile}`)
      } else openTribes()
    } else {
      closeTribes()
    }
  }, [isConnected])

  const { data: userData } = useQuery(
    ['userInfo', address],
    async () => {
      const userAddress = asEthWalletAddress(address)
      const data = await RecipientAPI.resolve([userAddress])
      return data[userAddress.value]
    },
    {
      enabled: !!address
    }
  )

  const {
    data: searchRes,
    isLoading,
    isFetched
  } = useQuery(
    ['search', debouncedSearchTerm, deepSearch],
    async () => {
      return await SearchAPI.search({
        type: SearchType.user,
        query: encodeURIComponent(debouncedSearchTerm.toLowerCase()),
        deepSearch,
        tiers:
          userData?.tier === UserTier.vip
            ? [UserTier.vip, UserTier.verified]
            : [UserTier.fresh, UserTier.vip, UserTier.verified]
      })
    },
    {
      enabled: !!debouncedSearchTerm
    }
  )

  const isError = !searchRes?.map && isFetched && query.length > 0
  const noResults =
    searchRes?.map && searchRes.length === 0 && query.length > 0

  return (
    <div
      className="bg-background text-primary flex flex-col overflow-auto"
      style={{
        height: '100vh'
      }}
    >
      <div className="flex flex-row justify-between w-full p-4 h-[4.5rem] items-center">
        {isFocused ? (
          <div className="flex flex-row gap-2 items-center ml-2">
            <img
              src={TribesAlpha}
              alt="Tribes Logo"
              className="h-10 dark:invert hidden lg:flex"
            />
            <img
              src={TribesLogo}
              alt="Tribes Logo"
              className="h-6 dark:invert flex lg:hidden"
            />
          </div>
        ) : (
          <div />
        )}
        <ConnectKitButton />
      </div>
      <div
        className={`flex flex-col items-center flex-grow md:mb-24
        mb-40 ${isFocused ? '' : 'justify-center'}`}
      >
        {!isFocused && (
          <div className="flex flex-col gap-2 items-center">
            <img src={TribesLogo} alt="logo" className="h-6 dark:invert" />
            <div className="text-base text-secondary font-normal">
              Chat with anyone on web3
            </div>
          </div>
        )}
        <div
          className={
            'w-full sm:w-[30rem] transition-all duration-300 ease-in-out flex flex-col items-center'
          }
        >
          <div className={`p-4 pt-0 ${isFocused ? 'w-full' : 'w-[23.5rem]'}`}>
            <div
              className="flex flex-row items-center border border-border h-10 rounded-full
            text-md px-4 py-2 mt-4"
            >
              <div className="mr-1">To:</div>
              <input
                value={query}
                className="w-full focus:outline-none placeholder-placeholder bg-transparent"
                type="search"
                name="search"
                placeholder="0x, Tribes, ENS or Lens"
                onChange={(e) => {
                  setQuery(e.target.value.trim())
                  setDeepSearch(false)
                }}
                onFocus={() => {
                  setIsFocused(true)
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                  }, 200)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setDeepSearch(true)
                  }
                }}
                onBlur={() => {
                  if (!debouncedSearchTerm) {
                    setIsFocused(false)
                  }
                }}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="w-full">
            {isError && (
              <div className="flex flex-col items-center justify-center p-4">
                <div className="text-sm text-secondary">
                  Something went wrong 😢
                </div>
              </div>
            )}
            {noResults && (
              <div className="flex flex-col items-center justify-center p-4">
                <div className="text-sm text-secondary">
                  No users match your search 😢
                </div>
                <div className="text-sm text-secondary">
                  Try a different search term
                </div>
              </div>
            )}
            {query.length > 0 && isLoading && (
              <div>
                {Array.from(Array(8).keys()).map((i) => (
                  <div role="status" className="max-w-sm animate-pulse" key={i}>
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row items-center gap-2 p-4 h-[4.5rem]">
                        <div className="h-10 w-10 rounded-full bg-border" />
                        <div className="flex flex-col gap-2">
                          <div className="h-4 w-32 bg-border rounded-lg" />
                          <div className="text-sm flex flex-row gap-2">
                            <div className="h-2 w-16 bg-border rounded-lg" />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 items-end">
                        <div className="h-2 w-12 bg-border rounded-lg" />
                        <div className="h-2 w-14 bg-border rounded-lg" />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {query.length > 0 &&
              !isLoading &&
              !isError &&
              searchRes?.map((item) => (
                <div className="flex flex-row items-center gap-2 cursor-pointer
                  p-4 h-[4.5rem] justify-between" key={item.walletAddress.value}
                onClick={() => {
                  setOpen(true)
                  setClickedProfile(item.displayName === item.walletAddress.prefix
                    ? item.walletAddress.value
                    : item.displayName)
                }}
                >
                  <div className="flex flex-row gap-2">
                    <img
                      src={item.image?.toString() ?? DefaultAvatar}
                      alt="avatar"
                      className="h-10 w-10 rounded-full"
                    />
                    <div className="flex flex-col">
                      <div className="flex flex-row items-center gap-1">
                        <div className="text-md font-semibold">
                          {trimToLength(item.displayName, 20)}
                        </div>
                        {item.tier === UserTier.vip && (
                          <img src={VipBadge} alt="logo" className="h-4" />
                        )}
                        {item.tier === UserTier.verified && (
                          <img
                            src={VerifiedBadge}
                            alt="logo"
                            className="h-4"
                          />
                        )}
                      </div>
                      <div className="text-sm text-secondary">
                        {item.secondaryName}
                      </div>
                    </div>
                  </div>
                  <div className="text-sm flex flex-col text-secondary items-end">
                    <div className="flex flex-row gap-0.5 items-start font-semibold">
                      {item.reputation} <span className="">⭐️</span>
                    </div>
                    {item.walletAge && (
                      <div>{timeSince(new Date(item.walletAge))}</div>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {!isFocused && (
            <div className={'flex sm:hidden absolute bottom-6'}>
              <a
                href="https://tribes.xyz"
                target="_blank"
                rel="noreferrer"
                className="flex flex-row items-center gap-1"
              >
                <span>
                  <img
                    src={InfoIcon}
                    alt="logo"
                    className="h-3.5 dark:invert"
                  />
                </span>
                <span className="text-secondary">About Tribes</span>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default LandingPage
