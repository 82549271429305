import { Secp256k1, Secp256k1PublicKey } from './secp256k1'

export const timeSince = (date: Date): string => {
  const now = new Date()
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000)

  const years = seconds / 31536000 // Number of seconds in a year

  if (years >= 1) {
    return years.toFixed(1) + 'y old'
  } else {
    const days = seconds / 86400 // Number of seconds in a day
    if (days < 1) {
      return 'less than a day old'
    } else {
      return Math.floor(days) + ' days old'
    }
  }
}

export const formatDate = (date: Date) => {
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'April', 'May', 'June',
    'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'
  ]

  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  const suffix = (day: number) => {
    if (day >= 11 && day <= 13) {
      return 'th'
    }
    switch (day % 10) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  }

  return `Week of ${monthNames[monthIndex]} ${day}${suffix(day)}, ${year}`
}

export const convertToPST = (date: Date) => {
  // Adjust to PST timezone (UTC-8)
  const offsetToPST = 8 - date.getTimezoneOffset() / 60
  date.setHours(date.getHours() - offsetToPST)
  return date
}

export const convertToMondayPST = (date: Date) => {
  const dayOfWeek = date.getDay()
  const differenceToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1
  date.setDate(date.getDate() - differenceToMonday)
  return date
}

export const convertToMonday = (date: Date) => {
  const dayOfWeek = date.getDay()
  const differenceToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1
  date.setDate(date.getDate() - differenceToMonday)

  // Set time to 4:20pm
  date.setHours(16, 20, 0, 0)

  const offsetToPST = 8 - date.getTimezoneOffset() / 60
  date.setHours(date.getHours() + offsetToPST)
  return date
}

export const convertToNextMonday = (date: Date) => {
  const dayOfWeek = date.getDay()
  const differenceToMonday = dayOfWeek === 0 ? 6 : dayOfWeek - 1
  date.setDate(date.getDate() - differenceToMonday + 7)

  // Set time to 4:20pm
  date.setHours(16, 20, 0, 0)

  const offsetToPST = 8 - date.getTimezoneOffset() / 60
  date.setHours(date.getHours() + offsetToPST)
  return date
}

export const trimToLength = (str: string, length: number) => {
  if (str.length <= length) {
    return str
  }
  return str.substr(0, length - 3) + '...'
}

export const getStartOfWeek = (date: Date) => {
  const dayOfWeek = date.getDay()
  const startOfWeek = new Date(date)
  startOfWeek.setDate(date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)) // Adjust if day is Sunday
  startOfWeek.setHours(0, 0, 0, 0) // Set to start of the day
  return startOfWeek
}

// Function to get the next Monday after a given date
export const getNextMonday = (date: Date) => {
  const nextMonday = new Date(date)
  nextMonday.setDate(nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7))
  nextMonday.setHours(0, 0, 0, 0) // Set to start of the day
  return nextMonday
}

export const generateProxy = () => {
  const proxy = Secp256k1.generateKeyPair()
  const pubKey = Secp256k1PublicKey.fromEcKeyPair(proxy)

  return {
    proxy,
    pubKey
  }
}
