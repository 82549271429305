import {
  type AssetSearchType,
  SearchType,
  type UserTier,
  asReputableRecipient,
  type ReputableRecipient,
  type Asset,
  asRecipientSearchResponse,
  type RecipientSearchResponse,
  asAsset
} from '../types'

type SearchParams =
  | {
      type: SearchType.user
      query: string
      tiers: UserTier[]
      deepSearch: boolean
    }
  | {
      type: AssetSearchType.coin | AssetSearchType.nft
      query: string
    }

// Overload signatures:
async function search(params: {
  type: SearchType.user
  query: string
  tiers: UserTier[]
  deepSearch: boolean
}): Promise<RecipientSearchResponse[]>

async function search(params: {
  type: AssetSearchType.coin | AssetSearchType.nft
  query: string
}): Promise<Asset[]>

async function search({ type, query, ...params }: SearchParams) {
  const res = await fetch(`${process.env.REACT_APP_API_ROUTE}/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      type,
      query,
      ...params
    })
  })
  const data: ReputableRecipient[] | Asset[] = await res.json()
  if (type === SearchType.user) {
    return data.map((recipient) => asRecipientSearchResponse(recipient))
  }
  return data.map((asset) => asAsset(asset))
}

export const SearchAPI = {
  search
}
