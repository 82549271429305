/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import TribesAlpha from '../../assets/tribes_alpha.png'
import { ConnectKitButton } from 'connectkit'
import { useAccount, useSignMessage } from 'wagmi'
import { LoginAPI } from '../../networking/loginAPI'
import { asEthWalletAddress } from '../../types'
import { Secp256k1 } from '../../utils/secp256k1'

const AdminLogin = () => {
  const { isConnected, address } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const [loading, setLoading] = useState(false)

  return (
    <div
      className="bg-background text-primary flex flex-col min-h-screen overflow-auto"
      style={{
        height: '100vh'
      }}
    >
      <div className="flex flex-row justify-between w-full p-4 h-[4.5rem] items-center">
        <div className="flex flex-row gap-2 items-center ml-2">
          <img
            src={TribesAlpha}
            alt="Tribes Logo"
            className="h-10 dark:invert hidden lg:flex"
          />
        </div>
      </div>
      <div className={'flex flex-col items-center flex-grow gap-4'}>
        <div>Login to access the admin pages</div>
        <div>
          {isConnected ? (
            <div
              className="bg-primary text-background rounded-lg px-4 py-2 cursor-pointer"
              onClick={async () => {
                setLoading(true)
                const { message, proxy } = await LoginAPI.getMessageToSign(
                  asEthWalletAddress(address)
                )
                const deviceSignature = Secp256k1.signMessage(message, proxy)
                const signature = await signMessageAsync({
                  message
                })
                await LoginAPI.loginWithSignature(
                  message,
                  signature,
                  deviceSignature
                )
                setLoading(false)
                window.history.back()
              }}
            >
              {loading ? 'Loading...' : 'Sign in'}
            </div>
          ) : (
            <ConnectKitButton />
          )}
        </div>
      </div>
    </div>
  )
}

export default AdminLogin
