import { atom } from 'jotai'

const queryAtom = atom('')
const deepSearchAtom = atom(false)
const isFocusedAtom = atom(false)
const isConnectedCacheAtom = atom(false)

export const store = {
  queryAtom,
  deepSearchAtom,
  isFocusedAtom,
  isConnectedCacheAtom
}
