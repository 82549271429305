import React, { useEffect, useState } from 'react'
import TribesAlpha from '../../assets/tribes_alpha.png'
import ENSLogo from '../../assets/ens.png'
import LensLogo from '../../assets/lens.png'
import UDLogo from '../../assets/ud.png'
import { useDebounce } from '@uidotdev/usehooks'
import { usePortfolio } from './usePortfolio'
import { AssetItem } from './asset'
import { NameResolutionSource } from '../../types'
import { LoginAPI } from '../../networking/loginAPI'
import { useNavigate } from 'react-router-dom'

const Portfolio = () => {
  const [query, setQuery] = useState('')
  const debouncedSearchTerm = useDebounce(query, 1000)
  const {
    data: portfolioAssets,
    isLoading,
    refetch
  } = usePortfolio(debouncedSearchTerm)

  const navigate = useNavigate()

  useEffect(() => {
    document.documentElement.classList.remove('dark')

    void LoginAPI.isAdminLoggedIn().then((isLoggedIn) => {
      if (!isLoggedIn) {
        navigate('/admin/login')
      }
    })
  }, [])

  return (
    <div
      className="bg-background text-primary flex flex-col min-h-screen overflow-auto"
      style={{
        height: '100vh'
      }}
    >
      <div className="flex flex-row justify-between w-full p-4 h-[4.5rem] items-center">
        <div className="flex flex-row gap-2 items-center ml-2">
          <img
            src={TribesAlpha}
            alt="Tribes Logo"
            className="h-10 dark:invert hidden lg:flex"
          />
        </div>
      </div>
      <div className={'flex flex-col items-center flex-grow mb-40'}>
        <div
          className={
            'w-full transition-all duration-300 ease-in-out flex flex-col items-center'
          }
        >
          <div className={'p-4 pt-0 w-[30rem]'}>
            <div
              className="flex flex-row items-center border border-border h-10 rounded-full
            text-md px-4 py-2 mt-4"
            >
              <div className="mr-1">Wallet:</div>
              <input
                value={query}
                className="w-full focus:outline-none placeholder-placeholder bg-transparent"
                type="search"
                name="search"
                placeholder="wallet address, ENS, lens or farcaster"
                onChange={(e) => {
                  setQuery(e.target.value)
                }}
                onFocus={() => {
                  setTimeout(() => {
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth'
                    })
                  }, 200)
                }}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
              />
            </div>
          </div>
          {query.length > 0 && isLoading && (
            <div className="w-[30rem] flex flex-col">
              {Array.from(Array(14).keys()).map((i) => (
                <div role="status" className="w-full pr-4 animate-pulse" key={i}>
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row items-center gap-2 p-4 h-[4.5rem]">
                      <div className="h-10 w-10 rounded-full bg-border" />
                      <div className="flex flex-col gap-2">
                        <div className="h-4 w-32 bg-border rounded-lg" />
                        <div className="text-sm flex flex-row gap-2">
                          <div className="h-2 w-16 bg-border rounded-lg" />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-2 items-end">
                      <div className="h-2 w-12 bg-border rounded-lg" />
                      <div className="h-2 w-14 bg-border rounded-lg" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {query.length > 0 && !isLoading && (
            <div className="w-[30rem] flex flex-col px-4">
              <div className="text-left font-semibold text-2xl w-full mb-4">
                Coins
              </div>
              {portfolioAssets?.coins.map((coin) => (
                <AssetItem item={coin} key={coin.address.value} refetch={refetch} />
              ))}
              <div className="text-left font-semibold text-2xl w-full my-4">
                NFTs
              </div>
              {portfolioAssets?.nfts.map((nft) => (
                <AssetItem item={nft} key={nft.address.value} refetch={refetch} />
              ))}
              <div className="text-left font-semibold text-2xl w-full my-4">
                Names
              </div>
              {portfolioAssets?.aliases.map((alias) => (
                <div key={alias.name} className='flex flex-row items-center py-4'>
                  <img src={
                    alias.source === NameResolutionSource.ens ? ENSLogo
                      : alias.source === NameResolutionSource.lens ? LensLogo
                        : alias.source === NameResolutionSource.unstoppable ? UDLogo : ''

                  } alt="logo" className="h-10 w-10 rounded-md" />
                  <div className="text-md font-semibold ml-2">
                    {alias.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Portfolio
