/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState, type PropsWithChildren } from 'react'
import * as ContextMenu from '@radix-ui/react-context-menu'
import { DotFilledIcon } from '@radix-ui/react-icons'
import { type AssetTier, asAssetTier } from '../types'
import { RSAOAEPHelper } from '../utils/webCrypto'
import { AdminService } from '../utils/adminService'

interface AssetContextMenuProps extends PropsWithChildren {
  id: string
  name: string
  address: string
  tier: AssetTier
  refetch: () => Promise<any>
}

const AssetContextMenu = ({
  name,
  id,
  address,
  tier,
  children,
  refetch
}: AssetContextMenuProps) => {
  const [assetTier, setAssetTier] = useState(tier)

  return (
    <ContextMenu.Root>
      <ContextMenu.Trigger>{children}</ContextMenu.Trigger>
      <ContextMenu.Portal>
        <ContextMenu.Content className="ContextMenuContent">
          <ContextMenu.Label className="ContextMenuLabel">
            {name}
          </ContextMenu.Label>
          <ContextMenu.RadioGroup
            value={assetTier}
            onValueChange={async (value) => {
              const token = await AdminService.getToken()
              setAssetTier(asAssetTier(value))
              void fetch(`${process.env.REACT_APP_API_ROUTE}/set_asset_tier`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({
                  id,
                  tier: value
                })
              }).then(() => {
                void refetch()
              })
            }}
          >
            <ContextMenu.RadioItem
              className="ContextMenuRadioItem"
              value="verified"
            >
              <ContextMenu.ItemIndicator className="ContextMenuItemIndicator">
                <DotFilledIcon />
              </ContextMenu.ItemIndicator>
              Verified
            </ContextMenu.RadioItem>
            <ContextMenu.RadioItem
              className="ContextMenuRadioItem"
              value="standard"
            >
              <ContextMenu.ItemIndicator className="ContextMenuItemIndicator">
                <DotFilledIcon />
              </ContextMenu.ItemIndicator>
              Standard
            </ContextMenu.RadioItem>
            <ContextMenu.RadioItem
              className="ContextMenuRadioItem"
              value="spam"
            >
              <ContextMenu.ItemIndicator className="ContextMenuItemIndicator">
                <DotFilledIcon />
              </ContextMenu.ItemIndicator>
              Spam
            </ContextMenu.RadioItem>
          </ContextMenu.RadioGroup>

          <ContextMenu.Separator className="ContextMenuSeparator" />

          <ContextMenu.Item
            className="ContextMenuItem"
            onSelect={() => {
              void navigator.clipboard.writeText(address)
            }}
          >
            Copy Address
          </ContextMenu.Item>
        </ContextMenu.Content>
      </ContextMenu.Portal>
    </ContextMenu.Root>
  )
}

export default AssetContextMenu
