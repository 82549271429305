import { ConnectKitProvider, getDefaultConfig } from 'connectkit'
import { WagmiConfig, createConfig } from 'wagmi'
import { mainnet, polygon, base } from '@wagmi/core/chains'
import { watchAccount } from '@wagmi/core'

import React, { useEffect, useState } from 'react'

import Leaderboard from './Leaderboard'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ChatPage from './ChatPage'
import Tribes from './components/tribes'
import Portfolio from './components/portfolio'
import Assets from './components/assets'
import LandingPage from './LandingPage'
import { tribesHandler } from './shared/constants'
import AdminLogin from './components/login'
import { useAtom } from 'jotai'
import { store } from './store'

const config = createConfig(
  getDefaultConfig({
    chains: [mainnet, polygon, base],
    // Required API Keys
    alchemyId: 'lys-Qs9wv0ptglRpnzpIeLNZ_OM9fOhx', // or infuraId
    walletConnectProjectId: 'c138b8bacdf7a4bcd7e15d23a47d9d86',

    // Required
    appName: 'Tribes',

    // Optional
    appDescription: 'Web3 Messenger',
    appUrl: 'https://www.tribes.xyz',
    appIcon: 'https://tribexyz.s3.amazonaws.com/app/logo-raw.png'
  })
)

window.addEventListener(
  'message',
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  async (event) => {
    const tribes = event.data.tribes
    if (typeof tribes === 'undefined' || tribes.isTribesResponse) {
      return
    }

    const requestId = tribes.requestId
    const method = tribes.method
    const args = tribes.args

    await tribesHandler.callMethod(method, args, requestId)
  },
  false
)

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [, setIsConnectedCache] = useAtom(store.isConnectedCacheAtom)

  useEffect(() => {
    let unwatch: () => void
    setTimeout(() => {
      unwatch = watchAccount((account) => {
        localStorage.setItem('connect_account_tribes', account.address as string)
        setIsConnectedCache(!!account.address)
      })
      setIsConnectedCache(!!localStorage.getItem('connect_account_tribes'))
      // delay to not check for account on initial load
    }, 1000)

    return () => {
      unwatch && unwatch()
    }
  }, [])

  const openTribes = () => {
    setIsOpen(true)
  }

  const closeTribes = () => {
    setIsOpen(false)
  }

  const queryClient = new QueryClient()

  const router = createBrowserRouter([
    {
      element: <Tribes isOpen={isOpen} />,
      children: [
        {
          path: '/',
          element: (
            <LandingPage
              closeTribes={closeTribes}
              openTribes={() => {
                setIsOpen(true)
              }}
            />
          )
        },
        {
          path: '/leaderboard',
          element: <Leaderboard closeTribes={closeTribes} />
        },
        {
          path: '/admin/portfolio',
          element: <Portfolio />
        },
        {
          path: '/admin/login',
          element: <AdminLogin />
        },
        {
          path: '/admin/:type',
          element: <Assets />
        },
        {
          path: '/invite/:id',
          element: <ChatPage openTribes={openTribes} />
        },
        {
          path: '/:user',
          element: <ChatPage openTribes={openTribes} />
        }
      ]
    }
  ])

  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider theme="auto" mode="auto">
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  )
}

export default App
