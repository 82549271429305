import { ConnectKitButton } from 'connectkit'
import React from 'react'
import TribesLogo from './TribesLogo'

const styles: {
  container: React.CSSProperties
} = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100vh',
    paddingLeft: 32,
    paddingRight: 32
  }

}

function isInviteURL(): boolean {
  const path = window.location.pathname
  return path.startsWith('/invite/')
}

const Welcome = () => {
  return (
    <div style={styles.container} id='welcome-container'>
      <TribesLogo />
      <h1 id='welcome-header-label'>Tribes Messenger</h1>
      <p id='welcome-title-label'>Chat and transact with anyone</p>
      <ConnectKitButton />
      {isInviteURL() && <p id='welcome-invite-label'>Have an invite? Accept it after connecting.</p>}
    </div>
  )
}

export default Welcome
