import React, { useEffect, useState } from 'react'

const styles: Record<string, React.CSSProperties> = {
  container: {
    width: 120,
    height: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 28
  },
  image: {
    width: 60,
    height: 60
  }
}

const TribesLogo: React.FC = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light')

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    const handleThemeChange = () => {
      setTheme(mediaQuery.matches ? 'dark' : 'light')
    }

    mediaQuery.addListener(handleThemeChange)
    handleThemeChange() // Set initial theme

    return () => { mediaQuery.removeListener(handleThemeChange) }
  }, [])

  const on1Color = theme === 'light' ? 'black' : 'white'

  return (
    <div style={{ ...styles.container, backgroundColor: on1Color }}>
      <img
        src="https://tribexyz.s3.amazonaws.com/app/logo-raw.png"
        alt="Tribes Logo"
        style={{ ...styles.image, filter: `invert(${theme === 'light' ? 0 : 1})` }}
      />
    </div>
  )
}

export default TribesLogo
